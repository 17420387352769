export const CONNECT_SPOTIFY_ACCOUNT_MUTATION = `
mutation connectSpotifyAccount(
  $accessToken: String!
  $refreshToken: String!
) {
  connectSpotifyAccount(
    input: {accessToken: $accessToken, refreshToken: $refreshToken}
  ) {
    id
    user {
      id
      firstName
      lastName
      username
      avatar
      gsToken
    }
  }
}
`;

export const DELETE_USER_MUTATION = `
mutation deleteUser($id: String!) {
  deleteUser(id: $id) {
    id
    username
  }
}
`;
