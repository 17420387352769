import React, { useEffect, useState } from "react";
import { createClient, Provider } from "urql";
import axios from "axios";
import { useQuery } from "urql";
import styled from "styled-components";
import Seo from "../components/Seo";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { SPOTIFY_CLIENT_ID, SPOTIFY_REDIRECT_URI } from "../constants";
import client from "../gql/client";
import SpotifyAuthCallbackHandler from "../components/SpotifyAuthCallbackHandler";

const Page = styled.div`
  min-height: 100vh;
  background: #000212;
  color: white;
`;

const Container = styled.div`
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
`;

const Main = styled.main`
  padding-top: 24vh;
`;

const SpotifyAuthCallback = ({ location }) => {
  return (
    <Provider value={client}>
      <Page>
        <Seo title="Spotify Auth Callback" />

        <Container>
          <Main>
            <SpotifyAuthCallbackHandler location={location} />
          </Main>
        </Container>
      </Page>
    </Provider>
  );
};

export default SpotifyAuthCallback;
