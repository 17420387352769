export const BASE_URL = "https://listty.app";
export const API_BASE_URL = "https://getlistty.com/graphql";
export const SPOTIFY_CLIENT_ID = "c1e6bc0569044fa08bd4ad036bd72aa9";
export const SPOTIFY_CLIENT_SECRET = "8b1850b8c4d84d538910cf14bd872f9d";
export const SPOTIFY_REDIRECT_URI = `${BASE_URL}/spotify_auth_callback`;
export const SPOTIFY_ACCOUNTS_BASE_URL = "https://accounts.spotify.com";
export const GET_TOKENS_ENDPOINT = `${SPOTIFY_ACCOUNTS_BASE_URL}/api/token`;
export const APP_STORE_URL =
  "https://apps.apple.com/rs/app/listty/id1620150284";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.listtyandroid";
export const DISCORD_INVITE_URL = "https://discord.gg/UyGsEzKqDu";
export const SPOTIFY_SCOPES = [
  "playlist-read-private",
  "playlist-modify-private",
  "playlist-modify-public",
  "user-read-private",
  "user-read-playback-state",
  "user-library-read",
  "user-read-recently-played",
  "user-library-modify",
  "app-remote-control",
  "playlist-read-collaborative",
  "user-modify-playback-state",
  "user-top-read",
  "ugc-image-upload",
];
