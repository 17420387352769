import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import {
  GET_TOKENS_ENDPOINT,
  SPOTIFY_CLIENT_ID,
  SPOTIFY_CLIENT_SECRET,
  SPOTIFY_REDIRECT_URI,
} from "../constants";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useMutation } from "urql";
import {
  CONNECT_SPOTIFY_ACCOUNT_MUTATION,
  DELETE_USER_MUTATION,
} from "../gql/mutations";
import styled from "styled-components";
import "../components/styles/main.css";

const ConfirmBtn = styled.button`
  background: #e12135;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

interface SpotifyAuthCallbackHandlerProps {
  location: any;
}
const SpotifyAuthCallbackHandler = ({
  location,
}: SpotifyAuthCallbackHandlerProps) => {
  const [spotifyTokens, setSpotifyTokens] = useState<
    { accessToken: string; refreshToken: string } | undefined
  >(undefined);

  const [
    { data: connectSpotifyAccountData, fetching: loadingConnectSpotifyAccount },
    executeConnectSpotifyAccountMutation,
  ] = useMutation(CONNECT_SPOTIFY_ACCOUNT_MUTATION);

  const [
    { data: deleteUserData, fetching: loadingDeleteUser },
    executeDeleteUserMutation,
  ] = useMutation(DELETE_USER_MUTATION);

  const [code, setCode] = useState("");
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (spotifyTokens === undefined) {
      return;
    }
    const { accessToken, refreshToken } = spotifyTokens;
    executeConnectSpotifyAccountMutation({ accessToken, refreshToken });
  }, [spotifyTokens]);

  useEffect(() => {
    const codeQ = params.get("code");
    if (codeQ) {
      setCode(codeQ);
    }
  }, [location]);

  useEffect(() => {
    if (spotifyTokens !== undefined) {
      return;
    }
    if (code) {
      const _getTokens = async () => {
        const formData = new FormData();
        formData.append("grant_type", "client_credentials");
        formData.append("code", code);
        formData.append("redirectUri", SPOTIFY_REDIRECT_URI);
        const str = `grant_type=authorization_code&code=${code}&redirect_uri=${SPOTIFY_REDIRECT_URI}`;
        try {
          const res = await axios.post<{
            refresh_token: string;
            access_token: string;
          }>(`${GET_TOKENS_ENDPOINT}`, str, {
            responseType: "json",
            headers: {
              Authorization:
                "Basic " +
                Buffer.from(
                  SPOTIFY_CLIENT_ID + ":" + SPOTIFY_CLIENT_SECRET
                ).toString("base64"),
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });

          const { access_token: accessToken, refresh_token: refreshToken } =
            res.data;

          if (!accessToken || !refreshToken) {
            return;
          }

          setSpotifyTokens({ accessToken, refreshToken });
        } catch (err) {
          console.error("[requestTokens] err: ", err);
        }
      };

      _getTokens();
    }
  }, [code, spotifyTokens]);
  const user = connectSpotifyAccountData?.connectSpotifyAccount.user;
  const deleted = deleteUserData?.deleteUser?.id;

  const onDelete = async () => {
    if (!user || deleted) {
      return;
    }
    await executeDeleteUserMutation({
      id: user?.id,
    });
  };

  return (
    <div>
      {loadingConnectSpotifyAccount ||
        (loadingDeleteUser && <ScaleLoader height="24px" color="white" />)}

      {user ? (
        <div style={{ marginBottom: "24px" }}>
          {deleted ? (
            <>
              <h2>
                {user.firstName}, your account has been deleted successfully.
              </h2>
            </>
          ) : (
            <>
              <h2>Hi {user.firstName}, we're sad to see you leaving! 😢</h2>
              <p
                style={{
                  marginBottom: "16px",
                }}
              >
                To delete your account please confirm by clicking on the button
                below.
              </p>

              <p>All of your data will be deleted immediately.</p>

              <ConfirmBtn disabled={deleted} onClick={onDelete}>
                Confirm
              </ConfirmBtn>
            </>
          )}
        </div>
      ) : (
        <div>
          <h2>Oops</h2>
          <p>
            Seems like there's no Listty account associated with the Spotify
            account you used to log in.
          </p>
        </div>
      )}
    </div>
  );
};

export default SpotifyAuthCallbackHandler;
